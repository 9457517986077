import React, { useEffect, useState, useContext } from "react";
import contentService from "../../services/api/content-service";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import Projects from "../projects/Projects";
import projectsService from "../../services/api/projects-service";
import UserContext from "../../UserContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle,faRedo , faDice } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import gamesService from "../../services/api/games-service"

// reactstrap componentsarticle_created_on
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Alert,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import { Link } from "react-router-dom";
import ReactDatetime from "react-datetime";
const Articles = (props) => {
  const projectId = new URLSearchParams(props.location.search).get("projectid");
  const context = useContext(UserContext);
  let categoryId = new URLSearchParams(props.location.search).get('categoryid');
  if(!categoryId){
    categoryId=""
  }
  const [formdetails, setFormDetails] = useState({
    categoryids: categoryId?[categoryId]:[],
    title: "",
    published_on_from_date:"" ,
    published_on_to_date:"",
    modified_on_from_date:"" , 
    modified_on_to_date:"" ,
    order_by :'',
    type:""
  });
  const [articles, setArticles] = useState(false);
  const [totalArticles, setTotalArticles] = useState(0);
  const [categories, setCategories] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedCategory,setSelectedCategory] = useState([]);
  const [projectDetails, setProjectDetails] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [renderComponent, setRenderComponent] = useState(false);
  const [loadingIds, setLoadingIds] = useState({});
  const [gamesEnabled,setGamesEnabled] = useState(false)
  const [enableGamesUrl, setEnableGamesUrl] = useState("")
  const [search, setSearchDetails] = useState({ categoryids: categoryId?[categoryId]:[], title: "" ,published_on_from_date:"" , published_on_to_date:"",modified_on_from_date:"" , modified_on_to_date:"" });
  const records = 10;
  const orderByOptions = [
    {
      value : "published_on",
      label : "Published On"
    },
    {
      value : "modified_on",
      label : "Modified On"
    },
  ];

  const typeOptions = [
    {
      value: "news",
      label: "News"
    },
    {
      value: "video",
      label: "Video"
    },
    {
      value: "gallery",
      label: "Gallery"
    },
    {
      value: "webstory",
      label: "Webstory"
    }
  ]

  useEffect(() => {
    (async () => {
      if (projectId) {
        let getProjectDetails = await projectsService.getProjectDetails(
          projectId
        );
        if (getProjectDetails.status) {
          setProjectDetails(getProjectDetails.data);
        }

        let categoryData = await contentService.getCategories(projectId);
        // if(!categoryData.status && categoryData.error.errorCode === 403){
        //   // context.setIsAuthorized(context);
        // }
        if (categoryData.status) {
          let allCategories = categoryData.data;
          let categoryArray = [];
          if (allCategories.length) {
            allCategories.forEach(function (categoryDetails) {
              categoryArray.push({
                value: categoryDetails._id,
                label: categoryDetails.name,
              });
              if (
                categoryDetails.sub_categories &&
                categoryDetails.sub_categories.length
              ) {
                let subCategories = categoryDetails.sub_categories;
                subCategories.forEach(function (subCategoryDetails) {
                  categoryArray.push({
                    value: subCategoryDetails._id,
                    label: subCategoryDetails.name,
                  });
                });
              }
            });
            setCategories(categoryArray);
          }
        }
      }
    })();
  }, []);


  useEffect(() => {
    if(!projectDetails) return
    (async () => {
      if(projectDetails?.settings?.enable_games === true){
        const games  = await gamesService.getGamesSettings({
          project_id:projectId
        })
        if(games && games.status && games.data?.status){
          setGamesEnabled(true)
        }
        else{
        setEnableGamesUrl(`/project/games?projectid=${projectId}`)
        }
      }
      else{
        setEnableGamesUrl(`/project/settings?projectid=${projectId}`)
      }
    })()
  }, [projectDetails])

  // useEffect(() => {
  //   (async () => {
  //     if (projectId) {
  //       let getTotalArticles = await contentService.getTotalArticles(
  //         projectId,
  //         formdetails
  //       );
  //       if (getTotalArticles.status) {
  //         setTotalArticles(getTotalArticles.data.count);
  //       }
  //     }
  //   })();
  // }, [search]);

  useEffect(() => {
    (async () => {
      if (projectId) {
        let articlesData = await contentService.getArticles(
          projectId,
          formdetails,
          activePage,
          records
        );
        
        if (articlesData.status && articlesData.data) {
          setArticles(articlesData.data.articles);
          setTotalArticles(articlesData.data.count);
        }
        setRenderComponent(true);
      }else if(!projectId){
        let articlesData = await contentService.getArticles(
          projectId,
          formdetails,
          activePage,
          records
        );
        if(!articlesData.status && articlesData.error.errorCode === 403){
          // context.setIsAuthorized(context);
          console.log();
          let newurl = window.location.protocol + "//" + window.location.host + "/dashboard/home"
          window.history.replaceState({},'',newurl)
        }
      }
    })();
  }, [activePage, search]);


  const handleGameClick = async (article) => {
    try {
      setLoadingIds((prev) => ({ ...prev, [article.id]: true }))
      let response = await contentService.updateGamesStatus({
        project_id: article.project_id,
        article_id: article.id,
        action: 'generate'
      });
      if (response && response.status && response.data && response.data.status) {
        
        setArticles((prevArticles) => {
          return prevArticles.map((item) =>
            item.id === article.id
          ? { ...item, games: response.data.games}
          : item
        );
      });
      setTimeout(() => {
        setLoadingIds((prev) => ({ ...prev, [article.id]: false }))
        toast.success("Job for games generation submitted successfully")
      }, 1000);
      }
      else {
        setTimeout(() => {
          setLoadingIds((prev) => ({ ...prev, [article.id]: false }))
          const errorMessage = response?.data?.error ?? 'An unexpected error occurred';
          toast.error(errorMessage);
        }, 1000);

      }
    } catch (err) {
      setTimeout(() => {
        setLoadingIds((prev) => ({ ...prev, [article.id]: false }))
        toast.error(`An unexpected error occurred`)
      }, 1000);
      return false
    }
  }

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setActivePage(pageNumber);
  };

  const onImageError = (event) => {
    // event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png";
    event.target.src =
      "https://mcmscache.epapr.in/mcms/461/9e326070049c91c0a19855f334fe114b53f733e0.svg";
  };


  const statusConfig = {
    default: { buttonText: "Generate", color: "#007bff" },// Blue for the default state (generate action) 
    generated: { buttonText: "Regenerate", color: "#28a745" }, // Green for successfully generated state
    inprogress: { buttonText: "Regenerate", color: "#ffc107" }, // Yellow for in-progress state 
    failed: { buttonText: "Regenerate", color: "#dc3545" }, // Red for failed state 
  };

  const getGamesInfo = (games) => {
    if (!games || Object.keys(games).length === 0) {
      return "No game info available";
    }
    let tooltipContent = `Status: ${games.status || "-"}<br />Generated On: ${new Date(games.generated_on).toLocaleString()}<br />`;
    if (games.fail_reason) {
      tooltipContent += `Fail Reason: ${games.fail_reason || "Unknown"}`;
    }

    return tooltipContent;
  };
  const getIconStyle = (type, gameStatus, isActive) => {
    if (!isActive) return type === "color" ? "#d3d3d3" : "not-allowed";
    return statusConfig[gameStatus]?.[type] || statusConfig.default[type];
  };

  let articlesElements = [];
  let firstRecord = 0;
  let lastRecord = 0;
  if (!articles || !articles.length) {
    if (!renderComponent) {
      articlesElements.push(
        <td colSpan="7" className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      );
    } else {
      articlesElements.push(
        <td colSpan="7" className="text-center">
          <CardTitle tag="h4">No Article Found</CardTitle>
        </td>
      );
    }
  } else {
    let i = 1;
    articles.forEach(function (articleDetails) {
      let className = "";
      const gamesInfo = getGamesInfo(articleDetails.games)
      if (!articleDetails.status) {
        className = "table-danger";
      }
      if (i == 1) {
        firstRecord = (activePage - 1) * records + i;
      }
      articlesElements.push(
        <tr id={"row_" + articleDetails.id} className={className}>
          <td className="text-left">{(activePage - 1) * 10 + i}</td>
          <td className="text-left">
            {articleDetails.thumburl ? (
              <img
                src={articleDetails.thumburl}
                width="50"
                height="50"
                onError={onImageError}
              ></img>
            ) : (
              ""
            )}
          </td>
          <td className="text-left">
            {articleDetails.title ? (
              articleDetails.type ==="webstory"? 
              <Link to={"/contentsettings/webstory_view?articleId=" + articleDetails.id}>
                {articleDetails.title}
              </Link>:
              <Link to={"/contentsettings/article_view?articleId=" + articleDetails.id}>
                {articleDetails.title}
              </Link>
            ) : (
              ""
            )}
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleDetails.article_created_on}
            </Moment>
          </td>
          <td className="text-left">
            <Moment format="dddd DD MMM YYYY hh:mm:ss a">
              {articleDetails.article_modified_on}
            </Moment>
          </td>
          <td className="text-left">
            {articleDetails.entities ? articleDetails.entities.join() : ""}
          </td>
          <td className="text-left">
            {articleDetails.status ? "active" : "deleted"}
          </td>

          {loadingIds[articleDetails.id] ?
            <td className="text-left">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </td>
            :
            <td className="text-left">
              <FontAwesomeIcon
                icon={
                  ["generated", "inprogress", "failed"].includes(articleDetails.games.status)
                    ? faRedo
                    : faDice
                }
                className="icon-action"
                onClick={() => {
                  if (gamesEnabled) {
                    if(articleDetails.status){
                      handleGameClick(articleDetails); 
                    }
                  } else {

                    let newurl = window.location.protocol + "//" + window.location.host + `${enableGamesUrl}`
                    console.log("window href ", newurl)
                    window.location.href = newurl
                  }
                }}
                style={{
                  fontSize: "20px",
                  color: getIconStyle("color", articleDetails.games.status, articleDetails.status),
                  cursor: articleDetails.status ? "pointer" : "not-allowed",
                  opacity: articleDetails.status ? 1 : 0.7,
                  marginRight: "10px", 
                }}
                data-tip={
                  gamesEnabled
                    ? statusConfig[articleDetails.games.status]?.buttonText || statusConfig.default.buttonText
                    : "Enable the Games"
                }              />
              <ReactTooltip effect="solid" html={true}/>
              {["generated", "inprogress", "failed"].includes(articleDetails.games.status) && (
                <>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="icon-info"
                    data-tip={gamesInfo}
                    data-place="top-end" 
                    style={{
                      fontSize: "20px", 
                      color: "#007bff",
                      cursor: "default",
                    }}
                  />
                  <ReactTooltip effect="solid" html={true}/>
                </>
              )}
            </td>}

        </tr>
      );
      if (i == articles.length) {
        lastRecord = (activePage - 1) * records + i;
      }
      i++;
      if (i == articles.length) {
        lastRecord = (activePage - 1) * records + i;
      }
    });
  }

  const handleCategoryChange = (value) => {
    let categoryIds = [];
    if (value && value.length > 0) {
      value.forEach(function (categoryIdDetails) {
        categoryIds.push(categoryIdDetails.value);
      });
    }
    setFormDetails({
      ...formdetails,
      categoryids: categoryIds,
    });
    setSelectedCategory(value);
  };

  const handleTitleChange = (event) => {
    setFormDetails({
      ...formdetails,
      title: event.target.value,
    });
  };

  const handleSubmit = function (e) {
    searchArticles();
    e.preventDefault();
  };

  const searchArticles = () => {
    setActivePage(1);
    setSearchDetails(formdetails);
  };

  const handleDateChange = (event,key) => {
      setFormDetails({
        ...formdetails,
        [key]: event._d,
      });
  }
  const handleOrderByChange = (event)=>{
    setFormDetails({
      ...formdetails,
      order_by: event.value,
    });
  }

  const handleTypeChange = (event) =>{
    setFormDetails({
      ...formdetails,
      type: event.value,
    });
  }

  const resetForm = () => {
    setActivePage(1)
    setFormDetails({
      ...formdetails,
      title : "",
      categoryids : [],
      published_on_from_date:"" ,
      published_on_to_date:"",
      modified_on_from_date:"" , 
      modified_on_to_date:"" ,
      order_by :'',
      type: ""
    });
    setSearchDetails({
      title : "",
      categoryids : [],
      published_on_from_date:"" ,
      published_on_to_date:"",
      modified_on_from_date:"" , 
      modified_on_to_date:"" ,
      order_by :'',
      type:""
    });
    setTitle("");
    setSelectedCategory([]);
  }
  const handleBulkUnsync = async () => {
      const response = await contentService.unsyncArticles({projectId});
      if(response.status && response.data) {
        console.log("Request Submitted for bulk unsync");
      }
  }

  if (!projectId) {
    return <Projects navigate="contentsettings/articles"></Projects>;
  } else {
    return (
      <div className="content">
        <Container>
          <div style={{'width':'100%'}}>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-signup text-center">
                <CardHeader>
                  <CardTitle tag="h4">
                    {projectDetails.name ? projectDetails.name + " - " : ""}{" "}
                    Articles
                  </CardTitle>
                  <Form
                    id="articlefilters"
                    action=""
                    className="form"
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    <Row>
                    <Row>
                      <Col md="4">
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          placeholder="select categories"
                          name="categoryids"
                          id="categoryids"
                          isMulti="true"
                          value = {selectedCategory}
                          options={categories}
                          onChange={(value) => handleCategoryChange(value)}
                        />
                      </Col>
                      <Col md="4">
                        <ReactDatetime
                            value={formdetails.published_on_from_date}
                            onChange={(event)=> handleDateChange(event,"published_on_from_date")}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Published On From Date",
                              id:"published_on_from_date",
                              autoComplete : "off",
                            }}
                            dateFormat ={"DD/MM/YYYY"}
                            closeOnSelect
                        />
                      </Col>
                      <Col md="4">
                        <ReactDatetime
                            value={formdetails.published_on_to_date}
                            onChange={(event)=> handleDateChange(event,"published_on_to_date")}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Published On To Date",
                              id:"published_on_to_date",
                              autoComplete : "off",
                            }}
                            dateFormat ={"DD/MM/YYYY"}
                            closeOnSelect
                          />
                      </Col>
                      </Row>
                      <Row>
                      <Col md="4">
                        <Input
                          type="text"
                          name="searchtitle"
                          id="searchtitle"
                          value = {formdetails.title}
                          onChange={handleTitleChange}
                          placeholder="Search in title"
                        />
                      </Col>
                      <Col md="4">
                        <ReactDatetime
                            value={formdetails.modified_on_from_date}
                            onChange={(event)=> handleDateChange(event,"modified_on_from_date")}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Modified On From Date",
                              id:"modified_on_from_date",
                              autoComplete : "off",
                            }
                          }
                          dateFormat ={"DD/MM/YYYY"}
                          closeOnSelect
                          />
                      </Col>
                      <Col md="4">
                        <ReactDatetime
                            value={formdetails.modified_on_to_date}
                            onChange={(event)=> handleDateChange(event,"modified_on_to_date")}
                            inputProps={{
                              className: "form-control",
                              placeholder: "Modified On To Date",
                              id:"modified_on_to_date",
                              autoComplete : "off",
                            }}
                            dateFormat ={"DD/MM/YYYY"}
                            closeOnSelect
                          />
                      </Col>
                      </Row>
                      <Row>
                      <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Order by"
                        name="order_by"
                        id="order_by"
                        options={orderByOptions}
                        value = {orderByOptions?orderByOptions.filter(option=> option.value == formdetails.order_by):""}
                        onChange={handleOrderByChange}
                      />  
                       </Col> 
                       <Col md="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Article Type"
                        name="type"
                        id="type"
                        options={typeOptions}
                        value = {typeOptions?typeOptions.filter(option=> option.value == formdetails.type):""}
                        onChange={handleTypeChange}
                      />  
                      </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div style={{'float':'left'}}>
                          <Button
                            className="btn-round"
                            color="info"
                            type="submit"
                          >
                            Search
                          </Button>
                              <Button
                                className="btn-round"
                                color="warning"
                                type="button"
                                onClick={resetForm}
                              >
                              Reset
                            </Button>
                            </div>
                        </Col>
                    </Row>
                    </Row>
                  </Form>
                  <hr></hr>
                  {totalArticles > 0 ?
                    <Row>
                      <Col md="6">
                        <div style={{ float: "right", margin: "5px" }}>
                          <b>
                            {firstRecord} - {lastRecord} of {totalArticles}
                          </b>
                        </div>
                      </Col>
                      <Col md="6">
                        <div style={{ float: "left" }}>
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={records}
                            totalItemsCount={totalArticles}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </Col>
                    </Row>
                  : null}
                  {/* <Button
                      className="btn-smb inUsr"
                      type="button"
                      onClick={handleBulkUnsync}
                      >
                        Bulk Unsync
                    </Button> */}
                    
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">#</th>
                        <th className="text-left">Thumb Image</th>
                        <th className="text-left">Title</th>
                        <th className="text-left">Created On</th>
                        <th className="text-left">Modified On</th>
                        <th className="text-left">Entities</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Games Action</th>
                      </tr>
                    </thead>
                    <tbody>{articlesElements}</tbody>
                  </Table>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
          </div>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("../../assets/img/bg/jan-sendereks.jpg")})`,
          }}
        />
         <ToastContainer autoClose={1000} position='bottom-right' />
      </div>
    );
  }
};

export default Articles;
