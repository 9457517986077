import axios from "axios";

const saveGamesSettings = async (formdata) => {
    try {
        const response = await axios.post('/api/games/save-games-config', formdata);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response status while saving game settings.');
        }
    } catch (error) {
        console.error('Error in saving game settings:', error.message || error);
        throw new Error('Failed to save game settings. Please check your input and try again.');
    }
};

const getGamesSettings = async (formdata) => {
    try {
        const response = await axios.post('/api/games/get-games-config', formdata);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unexpected response status while fetching game settings.');
        }
    } catch (error) {
        console.error('Error in fetching game settings:', error.message || error);
        throw new Error('Failed to fetch game settings. Please try again later.');
    }
};

const fetchGameDetails = async (formdata) => {
    try {
        const response = await axios.post('/api/games/get-game-details', formdata);
        return response.data;
    } catch (error) {
        console.error('Error in fetching game details:', error.message || error);
        throw new Error('Failed to fetch game details. Please try again later.');
    }
};

const fetchGames = async (formdata) => {
    try {
        const response = await axios.post('/api/games/get-games', formdata);
        return response.data;
    } catch (error) {
        console.error('Error in fetching games:', error.message || error);
        throw new Error('Failed to fetch games. Please try again later.');
    }
};
const getArticleTitle = async (projectId, formdetails, page, records) => {
    const formdata = {
        projectId: projectId,
        filters: formdetails,
        page: page,
        records: records
    };
    try {
        const response = await axios.post('/api/games/get-article-title', formdata);
        return response.data;
    } catch (error) {
        console.error('Error fetching article title:', error);
        throw new Error('Failed to fetch article title. Please try again later.');
    }
};

export default {
    saveGamesSettings,
    getGamesSettings,
    fetchGames,
    fetchGameDetails,
    getArticleTitle,
};
