
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Alert } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import contentService from "../../services/api/content-service";
import gamesService from '../../services/api/games-service';
import { useHistory } from 'react-router-dom';
const customStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: '#007bff',
        boxShadow: 'none',
        '&:hover': { borderColor: '#0056b3' },
        padding: '4px',
        borderRadius: '8px',
        width: '80%',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: '0.5rem',
        zIndex: 9999,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e9f5ff' : 'white',
        color: '#333',
        '&:hover': { backgroundColor: '#dceeff' },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#6c757d',
        fontSize: '14px',
    }),
};

const MAX_ARTICLES = 50;

const CreateGames = (props) => {
    const history = useHistory();
    const projectId = new URLSearchParams(props.location.search).get("projectid");
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [articles, setArticles] = useState([]);
    const [alert, setAlert] = useState({ show: false, type: '', message: '' });
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        (async () => {
            let categoryData = await contentService.getCategories(projectId);
            if (categoryData.status) {
                let allCategories = categoryData.data;
                let categoryArray = [];
                if (allCategories.length) {
                    allCategories.forEach(function (categoryDetails) {
                        categoryArray.push({
                            value: categoryDetails._id,
                            label: categoryDetails.name
                        });

                        if (categoryDetails.sub_categories && categoryDetails.sub_categories.length) {
                            let subCategories = categoryDetails.sub_categories;
                            subCategories.forEach(function (subCategoryDetails) {
                                categoryArray.push({
                                    value: subCategoryDetails._id,
                                    label: subCategoryDetails.name
                                });
                            });
                        }
                    });
                    setCategories(categoryArray);
                }
            }
        })();
    }, [projectId]);
    useEffect(() => {
        if (selectedCategory) {
            loadArticles('');
        }
    }, [selectedCategory]);

    const handleCategorySelect = (category) => {
        setSelectedArticle(null);
        setSelectedCategory(category ? [category.value] : []);
    };

    const handleSelect = (article) => {
        setSelectedArticle(article);
        console.log("Selected Article:", article);
    };

    const loadArticles = async (inputValue) => {
        try {
            const filters = {
                title: inputValue || '',
                categoryids: selectedCategory,
                order_by: "",
            };

            const response = await gamesService.getArticleTitle(projectId, filters, 1, MAX_ARTICLES);
            setArticles(response?.data?.articles || []);
            return response?.data?.articles?.map((article) => ({
                value: article.id,
                label: article.title,
            })) || [];
        } catch (error) {
            console.error("Error fetching articles:", error);
            return [];
        }
    };
    const updateGamesStatus = async () => {
        try {
            setIsLoading(true);
            console.log("updating game status")
            const gamesStatus = await contentService.updateGamesStatus({
                project_id: projectId,
                article_id: selectedArticle.value,
                action: 'generate'
            })
            if (gamesStatus && gamesStatus.data && gamesStatus.data.status) {
                setAlert({
                    show: true,
                    type: 'success',
                    message: 'Job to generate games sent successfully!'
                });
            }
            else {
                setAlert({
                    show: true,
                    type: 'danger',
                    message: gamesStatus.data.error || 'An error occurred',
                });
            }
        } catch (error) {
            setAlert({
                show: true,
                type: 'danger',
                message: error || 'An unexpected error occurred',
            });
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                setAlert({ show: false, type: '', message: '' });
            }, 3000);
        }
    }
    return (
        <div className="content mx-auto px-4 py-6">
            <Card className="shadow-lg rounded-lg">
                <CardBody className="p-6">
                    <h4 className="text-center font-bold mb-6 text-2xl">Create Games</h4>
                    <div className="mb-4">
                        <label
                            htmlFor="category-select"
                            className="block text-sm font-medium text-gray-700 mb-2"
                        >
                            Select Category
                        </label>
                        <Select
                            options={categories}
                            value={categories.find(category => category.value === selectedCategory)}
                            onChange={handleCategorySelect}
                            placeholder="Select a category..."
                            styles={customStyles}
                        />
                    </div>
                    <div className="mb-4">
                        {selectedCategory && (
                            <>
                                <label
                                    htmlFor="article-select"
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    Select Article
                                </label>
                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={loadArticles}
                                    value={selectedArticle}
                                    placeholder="Search for articles..."
                                    onChange={handleSelect}
                                    defaultOptions={articles.map(article => ({
                                        value: article.id,
                                        label: article.title
                                    }))}
                                    styles={customStyles}
                                />
                            </>

                        )}
                    </div>

                    <div className="flex justify-center space-x-4 mt-6">
                        <button
                            className={`btn btn-primary px-6 py-2 rounded-lg transition-all duration-300 ${
                                !selectedArticle || isLoading 
                                    ? 'opacity-50 cursor-not-allowed' 
                                    : 'hover:bg-blue-600'
                            }`}
                            onClick={updateGamesStatus}
                            disabled={!selectedArticle || isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                        <button
                            className="btn btn-secondary px-6 py-2 rounded-lg hover:bg-gray-200 transition-all duration-300"
                            onClick={() => history.goBack()}
                        >
                            Back
                        </button>
                    </div>
                    <div>
                        {alert.show && (
                            <Alert
                                color={alert.type}
                                className="mt-3 mt-4 text-center rounded-lg"
                                style={{ borderRadius: '10px' }}
                            >
                                {alert.message}
                            </Alert>
                        )}
                    </div>
                </CardBody>
            </Card>

        </div>
    );
};

export default CreateGames;
